/* eslint-disable no-empty-function */
import {
    ProductCompareDispatcher as SourceProductCompareDispatcher,
} from 'SourceStore/ProductCompare/ProductCompare.dispatcher';

/** @namespace Pwa/Store/ProductCompare/Dispatcher */
export class ProductCompareDispatcher extends SourceProductCompareDispatcher {
    async updateInitialProductCompareData(_dispatch) {

    }

    async getCompareList(_dispatch) {

    }
}

export default new ProductCompareDispatcher();
