import getStore from 'Util/Store';

/** @namespace Pwa/Util/Customer/Gtm/getCustomerGtmID */
export const getCustomerGtmID = () => {
    const state = getStore().getState();
    const {
        customer: { gtm: { id } },
    } = state.MyAccountReducer;

    return id;
};

/** @namespace Pwa/Util/Customer/Gtm/getCustomerIsSubscribed */
export const getCustomerIsSubscribed = () => {
    const state = getStore().getState();
    const {
        customer: { mc_newsletter_subscription },
    } = state.MyAccountReducer;

    return mc_newsletter_subscription;
};
