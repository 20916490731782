import { Field } from '@tilework/opus';

import { CartQuery as SourceCartQuery } from 'SourceQuery/Cart.query';

/** @namespace Pwa/Query/Cart/Query */
export class CartQuery extends SourceCartQuery {
    // _getCartItemFields() {
    //     return [
    //         'qty',
    //         'sku',
    //         'price',
    //         'item_id',
    //         'row_total',
    //         'row_total_incl_tax',
    //         'tax_amount',
    //         'tax_percent',
    //         'discount_amount',
    //         'discount_percent',
    //         'availability_from',
    //         this._getCustomizableOptionsFields(),
    //         this._getDownloadableLinksField(),
    //         this._getBundleOptionsField(),
    //         this._getProductField()
    //     ];
    // }

    _getCartItemsFields() {
        return [
            'id',
            'uid',
            'quantity',
            'sku',
            'availability_from',
            this._getCartItemProduct(),
            this._getCartItemPricesField(),
            this._getCartDownloadableProductLinkField(),
            this._getCartBundleProductFragment(),
            this._getCartConfigurableProductFragment(),
            this._getCartVirtualProductFragments(),
            this._getCartSimpleProductFragments(),
        ];
    }

    _getCartItemProduct() {
        return new Field('product').addFieldList(
            this._getProductInterfaceFields()
        );
    }

    _getProductInterfaceFields() {
        const fields = [
            'uid',
            'id',
            'sku',
            'name',
            'type_id',
            'salable_qty',
            'canonical_url',
            'url',
        ];

        fields.push(
            this._getProductImageField(),
            this._getProductThumbnailField(),
            this._getProductSmallField(),
            this._getStockItemField(),
            this._getPriceRangeField(),
            this._getDiscountImageField(),
            this._getMediaGalleryField(),
            this._getCategoriesField(),
        );

        fields.push(this._getAttributesField());

        return fields;
    }

    _getAttributesField() {
        return new Field('s_attributes')
            .setAlias('attributes')
            .addFieldList(this._getAttributeFields());
    }

    _getAttributeFields() {
        return [
            'attribute_id',
            'attribute_value',
            'attribute_code',
            'attribute_label',
            ...this._getAdditionalAttributeFields(),
            ...this._getAttributeOptionsFields(),
        ];
    }

    _getAdditionalAttributeFields() {
        return [
            'attribute_type',
            'attribute_group_id',
            'attribute_group_name',
        ];
    }

    _getAttributeOptionsFields() {
        return [
            this._getAttributeOptionsField(),
        ];
    }

    _getAttributeOptionsField() {
        return new Field('attribute_options')
            .addFieldList(this._getAttributeOptionField());
    }

    _getAttributeOptionField() {
        const fields = [
            'label',
            'value',
        ];

        fields.push(this._getSwatchDataField());

        return fields;
    }

    _getSwatchDataField() {
        return new Field('swatch_data')
            .addFieldList(this._getSwatchDataFields());
    }

    _getSwatchDataFields() {
        return [
            'type',
            'value',
        ];
    }

    _getStockItemField() {
        return new Field('stock_item')
            .addFieldList(this._getStockItemFields());
    }

    _getStockItemFields() {
        return [
            'in_stock',
            'min_sale_qty',
            'max_sale_qty',
            'qty_increments',
        ];
    }

    _getPriceRangeField() {
        return new Field('price_range')
            .addFieldList(this._getPriceRangeFields());
    }

    _getPriceRangeFields() {
        return [
            this._getMinimalPriceField(),
            this._getMaximalPriceField(),
        ];
    }

    _getMaximalPriceField() {
        return new Field('maximum_price')
            .addFieldList(this._getMinimalPriceFields());
    }

    _getMinimalPriceField() {
        return new Field('minimum_price')
            .addFieldList(this._getMinimalPriceFields());
    }

    _getMinimalPriceFields() {
        return [
            this._getDiscountField(),
            this._getFinalPriceField(),
            this._getFinalPriceExclTaxField(),
            this._getRegularPriceField(),
            this._getRegularPriceExclTaxField(),
            this._getDefaultPriceField(),
            this._getDefaultFinalPriceField(),
            this._getDefaultFinalPriceExclTaxField(),
        ];
    }

    _getDiscountField() {
        return new Field('discount')
            .addField('amount_off')
            .addField('percent_off');
    }

    _getFinalPriceField() {
        return new Field('final_price')
            .addField('currency')
            .addField('value');
    }

    _getFinalPriceExclTaxField() {
        return new Field('final_price_excl_tax')
            .addField('currency')
            .addField('value');
    }

    _getRegularPriceField() {
        return new Field('regular_price')
            .addField('currency')
            .addField('value');
    }

    _getRegularPriceExclTaxField() {
        return new Field('regular_price_excl_tax')
            .addField('currency')
            .addField('value');
    }

    _getDefaultFinalPriceExclTaxField() {
        return new Field('default_final_price_excl_tax')
            .addField('currency')
            .addField('value');
    }

    _getDefaultPriceField() {
        return new Field('default_price')
            .addField('currency')
            .addField('value');
    }

    _getDefaultFinalPriceField() {
        return new Field('default_final_price')
            .addField('currency')
            .addField('value');
    }

    _getMediaGalleryField() {
        return new Field('media_gallery_entries')
            .addFieldList(this._getMediaGalleryFields());
    }

    _getMediaGalleryFields() {
        return [
            'id',
            'file',
            'label',
            'position',
            'disabled',
            'media_type',
            'types',
            this._getVideoContentField(),
            this._getMediaThumbnailField(),
            this._getMediaBaseField(),
            this._getMediaLargeField(),
        ];
    }

    _getVideoContentField() {
        return new Field('video_content').addFieldList([
            'media_type',
            'video_description',
            'video_metadata',
            'video_provider',
            'video_title',
            'video_url',
        ]);
    }

    _getMediaThumbnailField() {
        return new Field('thumbnail').addField('url');
    }

    _getMediaBaseField() {
        return new Field('base').addField('url');
    }

    _getMediaLargeField() {
        return new Field('large').addField('url');
    }

    _getCategoriesField() {
        return new Field('categories')
            .addFieldList(this._getCategoryFields());
    }

    _getCategoryFields() {
        return [
            'id',
            'name',
            'url',
        ];
    }

    _getProductImageField() {
        return new Field('image')
            .addFieldList(this._getProductThumbnailFields());
    }

    _getProductThumbnailFields() {
        return [
            'path',
            'url',
        ];
    }

    _getProductThumbnailField() {
        return new Field('thumbnail')
            .addFieldList(this._getProductThumbnailFields());
    }

    _getProductSmallField() {
        return new Field('small_image')
            .addFieldList(this._getProductSmallFields());
    }

    _getProductSmallFields() {
        return this._getProductThumbnailFields();
    }

    _getDiscountImageField() {
        return new Field('mc_flashsale')
            .addFieldList(this._getFlashSalesFields());
    }

    _getFlashSalesFields() {
        const discount_image = new Field('discount_image').addFieldList(['standard', 'xs']);

        return [
            'discount_code',
            discount_image,
            'items_total',
            'items_remaining',
            'time_start',
            'time_end',
            'is_coming',
            'cms_block',
            'is_closed',
            'closed_image',
        ];
    }
}

export default new CartQuery();
