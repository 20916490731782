import {
    addComparedProductIds as sourceAddComparedProductIds,
    clearComparedProducts as sourceClearComparedProducts,
    removeComparedProduct as sourceRemoveComparedProduct,
    setCompareList as sourceSetCompareList,
    setCompareListIds as sourcesetCompareListIds,
    toggleLoader as sourceToggleLoader,
    updateCompareTotals as sourceupdateCompareTotals,
} from 'SourceStore/ProductCompare/ProductCompare.action';
import { ProductCompareActionType as sourceProductCompareActionType } from 'SourceStore/ProductCompare/ProductCompare.type';

// TODO: implement TOGGLE_COMPARE_LIST_LOADER
export const TOGGLE_COMPARE_LIST_LOADER = sourceProductCompareActionType.SOURCE_TOGGLE_COMPARE_LIST_LOADER;

// TODO: implement SET_COMPARE_LIST
export const SET_COMPARE_LIST = sourceProductCompareActionType.SOURCE_SET_COMPARE_LIST;

// TODO: implement REMOVE_COMPARED_PRODUCT
export const REMOVE_COMPARED_PRODUCT = sourceProductCompareActionType.SOURCE_REMOVE_COMPARED_PRODUCT;

// TODO: implement CLEAR_COMPARED_PRODUCTS
export const CLEAR_COMPARED_PRODUCTS = sourceProductCompareActionType.SOURCE_CLEAR_COMPARED_PRODUCTS;

// TODO: implement SET_COMPARED_PRODUCT_IDS
export const SET_COMPARED_PRODUCT_IDS = sourceProductCompareActionType.SOURCE_SET_COMPARED_PRODUCT_IDS;

// TODO: implement ADD_COMPARED_PRODUCT_ID
export const ADD_COMPARED_PRODUCT_ID = sourceProductCompareActionType.SOURCE_ADD_COMPARED_PRODUCT_ID;

// TODO: implement toggleLoader
export const toggleLoader = sourceToggleLoader;

// TODO: implement setCompareList
export const setCompareList = sourceSetCompareList;

// TODO: implement removeComparedProduct
export const removeComparedProduct = sourceRemoveComparedProduct;

// TODO: implement clearComparedProducts
export const clearComparedProducts = sourceClearComparedProducts;

// TODO: implement setComparedProductIds
// export const setComparedProductIds = sourceSetComparedProductIds;

// TODO: implement addComparedProductIds
export const addComparedProductIds = sourceAddComparedProductIds;

export const setCompareListIds = sourcesetCompareListIds;

export const updateCompareTotals = sourceupdateCompareTotals;
