import Cookie from 'js-cookie';

import CartQuery from 'Query/Cart.query';
import RebuildCartQuery from 'Query/RebuildCart.query';
import { CartDispatcher as SourceCartDispatcher } from 'SourceStore/Cart/Cart.dispatcher';
import { updateIsLoadingCart, updateTotals } from 'Store/Cart/Cart.action';
import { CART_TOTALS } from 'Store/Cart/Cart.reducer';
import {
    updateEmail,
    updateShippingFields,
} from 'Store/Checkout/Checkout.action';
import { resetLoading } from 'Store/Configuratore/Configuratore.action';
import MyAccountDispatcher from 'Store/MyAccount/MyAccount.dispatcher';
import { showNotification } from 'Store/Notification/Notification.action';
import { NotificationType } from 'Store/Notification/Notification.type';
import { changeLoader } from 'Store/Warehouse/Warehouse.action';
import { isSignedIn } from 'Util/Auth/IsSignedIn';
import { getAuthorizationToken } from 'Util/Auth/Token';
import BrowserDatabase from 'Util/BrowserDatabase/BrowserDatabase';
import { getCartId, setCartId } from 'Util/Cart';
import { getErrorMessage } from 'Util/Request/Error';
import { fetchMutation } from 'Util/Request/Mutation';
import { fetchQuery } from 'Util/Request/Query';

export const GTMDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/GTM/GTM.dispatcher'
);

/** @namespace Pwa/Store/Cart/Dispatcher */
export class CartDispatcher extends SourceCartDispatcher {
    async recoverBrokenCart(dispatch) {
    // MCT-1357
    // dispatch(showNotification('error',
    //     __('One error occurred. Your cart has been restored. Please try again.')));
        dispatch(resetLoading());

        if (isSignedIn()) {
            return MyAccountDispatcher.logout(false, dispatch);
        }

        return this.createGuestEmptyCart(dispatch);
    }

    /**
   * Refresh cart can be uset to manually force the cart refresh (see MyAccount dispatcher)
   * @param dispatch
   * @returns {Promise<void>}
   */
    async refreshCart(dispatch, gqid) {
        const loggedIn = isSignedIn();
        const quoteId = gqid || getCartId();
        const { cartData = {} } = await fetchQuery(
            CartQuery.getCartQuery(!loggedIn && quoteId)
        );

        dispatch(changeLoader(false));

        return this._updateCartData(cartData, dispatch);
    }

    /**
   * Optimized version of updateInitialCartData.
   * ScandiPWA starts a backend cart call at every new visit
   * This fix avoids non needed cart calls
   *
   * @param dispatch
   * @returns {Promise<null|void|*|string|undefined>}
   */
    // async updateInitialCartData(
    //     dispatch,
    //     isForCustomer = false,
    //     disableLoader = false,
    // ) {

    //     try {

    //         // ! Get quote token first (local or from the backend) just to make sure it exists

    //         if (!disableLoader) {
    //             dispatch(updateIsLoadingCart(true));
    //         }

    //         let quoteId = null;
    //         if (window.location.pathname === '/cart') {
    //             let maskedId = new Proxy(new URLSearchParams(window.location.search), {
    //                 get: (searchParams, prop) => searchParams.get(prop)
    //             }).gqid;
    //             if (maskedId) {
    //                 quoteId = await setCartId(maskedId);
    //             }
    //         } else {

    //             // ! Get quote token first (local or from the backend) just to make sure it exists
    //             quoteId = await this._getCartId(dispatch);
    //         }

    //         const {
    //             cartData = {},
    //             cartData: {
    //                 is_virtual = false,
    //                 shipping_addresses: [{
    //                     selected_shipping_method = null,
    //                 } = {}] = [],
    //             } = {},
    //         } = await fetchQuery(
    //             CartQuery.getCartQuery(
    //                 quoteId || '',
    //             ),
    //         );

    //         if (selected_shipping_method) {
    //             const {
    //                 address,
    //                 address: {
    //                     email = '',
    //                     street = '',
    //                 } = {},
    //                 method_code = '',
    //             } = selected_shipping_method;

    //             if (address && street) {
    //                 if (!is_virtual) {
    //                     await dispatch(
    //                         updateShippingFields({
    //                             ...this.prepareCheckoutAddressFormat(address),
    //                             method_code,
    //                         }),
    //                     );
    //                 }

    //                 await dispatch(updateEmail(email));
    //             }
    //         }

    //         if (isForCustomer && !getAuthorizationToken()) {
    //             dispatch(updateIsLoadingCart(false));

    //             return null;
    //         }

    //         await this._updateCartData(cartData, dispatch);

    //         if (!disableLoader) {
    //             dispatch(updateIsLoadingCart(false));
    //         }

    //         return null;
    //     } catch (error) {
    //         dispatch(updateIsLoadingCart(false));

    //         return this.createGuestEmptyCart(dispatch);
    //     }
    // }

    async updateInitialCartData(
        dispatch,
        isForCustomer = false,
        disableLoader = false
    ) {
    // Need to get current cart from BE, update cart
        try {
            // ! Get quote token first (local or from the backend) just to make sure it exists

            if (!disableLoader) {
                dispatch(updateIsLoadingCart(true));
            }

            // ! Get quote token first (local or from the backend) just to make sure it exists
            const quoteId = await this._getCartId(dispatch);
            const {
                cartData = {},
                cartData: {
                    is_virtual = false,
                    shipping_addresses: [{ selected_shipping_method = null } = {}] = [],
                } = {},
            } = await fetchQuery(CartQuery.getCartQuery(quoteId || ''));

            if (selected_shipping_method) {
                const {
                    address,
                    address: { email = '', street = '' } = {},
                    method_code = '',
                } = selected_shipping_method;

                if (address && street) {
                    if (!is_virtual) {
                        await dispatch(
                            updateShippingFields({
                                ...this.prepareCheckoutAddressFormat(address),
                                method_code,
                            })
                        );
                    }

                    await dispatch(updateEmail(email));
                }
            }

            if (isForCustomer && !getAuthorizationToken()) {
                dispatch(updateIsLoadingCart(false));

                return null;
            }

            await this._updateCartData(cartData, dispatch);

            if (!disableLoader) {
                dispatch(updateIsLoadingCart(false));
            }
            // dispatch(changeLoader(false));

            return null;
        } catch (error) {
            dispatch(updateIsLoadingCart(false));

            return this.createGuestEmptyCart(dispatch);
        }
    }

    /**
   * Crete emtpy cart (see success page)
   * @param dispatch
   * @returns {Promise<string|null>}
   */
    async createEmptyCart(dispatch) {
        try {
            const { createEmptyCart: quoteId = '' } = await fetchMutation(
                CartQuery.getCreateEmptyCartMutation()
            );

            setCartId(quoteId);
            this._updateCartData({}, dispatch);

            return quoteId;
        } catch (error) {
            dispatch(showNotification('error', getErrorMessage(error)));

            return null;
        }
    }

    async addProductToCart(dispatch, options, _cont = 0) {
        const { products = [], cartId: userCartId } = options;

        let cartId = userCartId || getCartId();

        if (!Array.isArray(products) || products.length === 0) {
            dispatch(showNotification(NotificationType.ERROR, __('No product data!')));

            return Promise.resolve();
        }

        try {
            if (!cartId) {
                cartId = await this.createGuestEmptyCart(dispatch);
            }

            const cartItems = BrowserDatabase.getItem(CART_TOTALS);
            const { addProductsToCart: { user_errors: errors = [] } = {} } = await fetchMutation(
                CartQuery.getAddProductToCartMutation(cartId, products)
            );

            if (Array.isArray(errors) && errors.length > 0) {
                errors.forEach((error) => {
                    dispatch(
                        showNotification(NotificationType.ERROR, getErrorMessage(error))
                    );
                });

                return Promise.resolve();
            }

            await this.updateInitialCartData(dispatch);

            const cartData = BrowserDatabase.getItem(CART_TOTALS);

            const oldSkuQty = cartItems?.items?.find(
                (elem) => elem.sku === products[0].sku
            );
            const newSkuQty = cartData?.items?.find(
                (elem) => elem.sku === products[0].sku
            );

            if (oldSkuQty && newSkuQty && oldSkuQty.quantity === newSkuQty.quantity) {
                BrowserDatabase.setItem('limitedItem', true);
            }

            // dispatch(showNotification(NotificationType.SUCCESS, __('Product was added to cart!')));
        } catch (error) {
            if (!navigator.onLine) {
                dispatch(
                    showNotification(
                        NotificationType.ERROR,
                        __('Not possible to fetch while offline')
                    )
                );

                return Promise.reject();
            }

            dispatch(showNotification(NotificationType.ERROR, getErrorMessage(error)));

            return Promise.reject();
        }

        return Promise.resolve();
        // Create an empty cart if missing
        // const isCustomerSignedIn = isSignedIn();
        // if (!isCustomerSignedIn && !getGuestQuoteId()) {
        //     await this.createGuestEmptyCart(dispatch);
        // }

        // console.log('addProductToCart dispatch options', options);

        // const {
        //     products,
        //     quantity,
        //     productOptionsData,
        //     buyRequest
        // } = options;

        // if (!Array.isArray(products) || products.length === 0) {
        //     dispatch(showNotification(NotificationType.ERROR, __('No product data!')));
        //     return;
        // }

        // const product = products[0];

        // const {
        //     sku,
        //     type_id: product_type
        // } = product;

        // const {
        //     productOptions,
        //     productOptionsMulti,
        //     downloadableLinks
        // } = productOptionsData || {};

        // const productToAdd = {
        //     sku,
        //     product_type,
        //     quantity,
        //     product_option: {
        //         buy_request: buyRequest,
        //         extension_attributes: getExtensionAttributes(
        //             {
        //                 ...product,
        //                 productOptions,
        //                 productOptionsMulti,
        //                 downloadableLinks
        //             }
        //         )
        //     }
        // };

        // try {
        //     const guestQuoteId = !isCustomerSignedIn && getGuestQuoteId();

        //     if (!isCustomerSignedIn && !guestQuoteId) {
        //         return Promise.reject();
        //     }

        //     console.log('addProductToCart dispatch guestQuoteId', guestQuoteId);

        //     const cartItems = BrowserDatabase.getItem(CART_TOTALS);
        //     const { saveCartItem: { cartData = {} } = {} } = await fetchMutation(
        //         CartQuery.getSaveCartItemMutation(productToAdd, guestQuoteId)
        //     );

        //     const oldSkuQty = cartItems.items?.find((elem) => elem.sku === productToAdd.sku);
        //     const newSkuQty = cartData.items?.find((elem) => elem.sku === productToAdd.sku);

        //     if (oldSkuQty && newSkuQty && oldSkuQty.quantity === newSkuQty.quantity) {
        //         BrowserDatabase.setItem(true, 'limitedItem');
        //     }

        //     console.log('addProductToCart dispatch cartData', cartData);

        //     this._updateCartData(cartData, dispatch);
        // } catch (error) {
        //     if (error[0] && error[0].extensions?.category === 'internal') {
        //         await this.recoverBrokenCart(dispatch);
        //         // eslint-disable-next-line no-magic-numbers
        //         if (cont < 3) {
        //             this.addProductToCart(dispatch, options, cont + 1);
        //         }

        //         return Promise.reject();
        //     }

        //     dispatch(showNotification('error', getErrorMessage(error)));
        //     return Promise.reject();
        // }

    // return Promise.reject();
    }

    async removeProductFromCart(dispatch, item_id) {
        try {
            const isCustomerSignedIn = isSignedIn();
            const cartId = getCartId() || '';

            if (!isCustomerSignedIn && !cartId) {
                return null;
            }

            const { removeItemFromCart: { cartData = {} } = {} } = await fetchMutation(
                CartQuery.getRemoveCartItemMutation(item_id, cartId)
            );

            this._updateCartData(cartData, dispatch);

            return cartData;
        } catch (error) {
            dispatch(showNotification(NotificationType.ERROR, getErrorMessage(error)));

            return null;
        }

        // try {
        //     const isCustomerSignedIn = isSignedIn();
        //     const guestQuoteId = !isCustomerSignedIn && getCartId();

        //     if (!isCustomerSignedIn && !guestQuoteId) {
        //         return null;
        //     }

        //     const cartItem = getCartItemById(item_id);

        //     const { removeCartItem: { cartData = {} } = {} } = await fetchMutation(
        //         CartQuery.getRemoveCartItemMutation(item_id, guestQuoteId)
        //     );

        //     GTMDispatcher.then(
        //         ({ default: dispatcher }) => dispatcher.pushRemoveFromCart(dispatch, cartItem)
        //     );

    //     this._updateCartData(cartData, dispatch);
    //     return cartData;
    // } catch (error) {
    //     dispatch(showNotification('error', getErrorMessage(error)));
    //     await this.recoverBrokenCart(dispatch);
    //     return null;
    // }
    }

    // async changeItemQty(dispatch, options) {
    //     const { item_id, quantity, sku } = options;
    //     const oldCartItem = getCartItemById(item_id);

    //     try {
    //         const isCustomerSignedIn = isSignedIn();
    //         const guestQuoteId = !isCustomerSignedIn && getGuestQuoteId();

    //         if (!isCustomerSignedIn && !guestQuoteId) {
    //             return Promise.reject();
    //         }

    //         const cartItems = BrowserDatabase.getItem(CART_TOTALS);

    //         const { saveCartItem: { cartData = {} } = {} } = await fetchMutation(
    //             CartQuery.getSaveCartItemMutation(
    //                 { sku, item_id, quantity },
    //                 guestQuoteId
    //             )
    //         );

    //         const oldSkuQty = cartItems.items?.find((elem) => elem.sku === sku);
    //         const newSkuQty = cartData.items?.find((elem) => elem.sku === sku);

    //         if (oldSkuQty && newSkuQty && oldSkuQty.quantity === newSkuQty.quantity) {
    //             BrowserDatabase.setItem(true, 'limitedItemCartItem');
    //         }

    //         if (quantity > oldCartItem.qty) {
    //             // pushAddToCart(oldCartItem.product, 'cart');
    //             GTMDispatcher.then(
    //                 ({ default: dispatcher }) => dispatcher.pushAddToCart(dispatch, oldCartItem.product, 'cart')
    //             );
    //         } else {
    //             // EVENTO GA4
    //             window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
    //             window.dataLayer.push({
    //                 event: 'remove_from_cart',
    //                 ecommerce: {
    //                     value: oldCartItem.price,
    //                     currency: 'EUR',
    //                     items: [
    //                         {
    //                             item_id: oldCartItem.product.sku,
    //                             item_name: oldCartItem.product.name,
    //                             affiliation: 'store online',
    //                             currency: 'EUR',
    //                             discount: oldCartItem.product?.price_range?.maximum_price?.discount?.amount_off,
    //                             index: 0,
    //                             item_brand: 'Mondo Convenienza',
    //                             item_category: oldCartItem.product?.attributes?.mc_techspecs_template.attribute_value,
    //                             item_list_name: oldCartItem.product?.attributes?.mc_techspecs_template.attribute_value ? `Categoria ${ oldCartItem.product?.attributes?.mc_techspecs_template.attribute_value }` : undefined,
    //                             price: oldCartItem.price,
    //                             quantity,
    //                         },
    //                     ],
    //                 },
    //             });
    //             oldCartItem.qty = 1;
    //             // pushRemoveFromCart(oldCartItem);
    //             GTMDispatcher.then(
    //                 ({ default: dispatcher }) => dispatcher.pushRemoveFromCart(dispatch, oldCartItem)
    //             );
    //         }

    //         return this._updateCartData(cartData, dispatch);
    //     } catch (error) {
    //         dispatch(showNotification('error', getErrorMessage(error)));
    //         await this.recoverBrokenCart(dispatch);

    //         return Promise.reject();
    //     }
    // }

    async changeItemQty(dispatch, options) {
        const { quantity } = options;
        try {
            const isCustomerSignedIn = isSignedIn();
            const guestQuoteId = !isCustomerSignedIn && getCartId();

            if (!isCustomerSignedIn && !guestQuoteId) {
                return Promise.reject();
            }

            const cartId = getCartId();

            await fetchMutation(
                CartQuery.getUpdateCartItemsMutation({
                    cart_id: cartId,
                    cart_items: [
                        {
                            cart_item_uid: options?.uid,
                            quantity,
                        },
                    ],
                })
            );

            return await this.updateInitialCartData(dispatch);
        } catch (error) {
            dispatch(showNotification('error', getErrorMessage(error)));
            // await this.recoverBrokenCart(dispatch);

            return Promise.reject();
        }
    }

    async rebuildCart(dispatch) {
        try {
            const quoteId = await this._getGuestQuoteId(dispatch);
            const failedCartData = Cookie.get('mc_failed_cart');

            if (!failedCartData) {
                return;
            }
            Cookie.remove('mc_failed_cart');
            await fetchMutation(
                RebuildCartQuery.getMutation(JSON.parse(failedCartData), quoteId)
            );
            const { cartData = {} } = await fetchQuery(
                CartQuery.getCartQuery(!isSignedIn() && quoteId)
            );

            dispatch(updateTotals(cartData));
        } catch (error) {
            dispatch(showNotification('error', getErrorMessage(error)));
        }
    }
}

export default new CartDispatcher();
