import { Field } from '@tilework/opus';

import {
    MyAccountQuery as SourceMyAccountQuery,
} from 'SourceQuery/MyAccount.query';

/** @namespace Pwa/Query/MyAccount/Query */
export class MyAccountQuery extends SourceMyAccountQuery {
    _getCustomerFields() {
        return [
            'created_at',
            'confirmation_required',
            'group_id',
            'prefix',
            'firstname',
            'middlename',
            'lastname',
            'suffix',
            'email',
            'default_billing',
            'default_shipping',
            'dob',
            'taxvat',
            'id',
            'is_subscribed',
            'opt_in',
            this._getAddressesField(),
            this._getGtmCustomerField(),
        ];
    }

    _getCustomerFieldsWithoutGtm() {
        return [
            'created_at',
            'confirmation_required',
            'group_id',
            'prefix',
            'firstname',
            'middlename',
            'lastname',
            'suffix',
            'email',
            'default_billing',
            'default_shipping',
            'dob',
            'taxvat',
            'id',
            'is_subscribed',
            'opt_in',
            this._getAddressesField(),
        ];
    }

    _getCustomerFieldWithoutGtm() {
        return new Field('customer')
            .addFieldList(this._getCustomerFieldsWithoutGtm());
    }

    _getGtmCustomerField() {
        return new Field('gtm')
            .addFieldList(this._getGtmCustomerFields());
    }

    _getGtmCustomerFields() {
        return [
            'id',
            'level',
        ];
    }

    getToggleNewsletterMutation() {
        return new Field('mcToggleNewsletterSubscription')
            .addField(this._getCustomerField());
    }

    getCreateAccountMutation(options) {
        const { customer, password } = options;

        return new Field('createCustomer')
            .addArgument('input', 'CustomerInput!', { ...customer, password })
            .addField(this._getCustomerFieldWithoutGtm());
    }
}

export default new MyAccountQuery();
