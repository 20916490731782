import { Field } from '@tilework/opus';

import {
    MyAccountQuery as SourceMyAccountQuery,
} from 'SourceQuery/MyAccount.query';

/** @namespace Pwa/Query/MyAccountNewsletterSubscription/Query */
export class MyAccountNewsletterSubscriptionQuery extends SourceMyAccountQuery {
    _getCustomerDataWithNLSubscriptionField() {
        return new Field('customer')
            .addFieldList(this._getCustomerDataWithNewsletterSubscription());
    }

    _getCustomerDataWithNewsletterSubscription() {
        return [
            'created_at',
            'confirmation_required',
            'group_id',
            'prefix',
            'firstname',
            'middlename',
            'lastname',
            'suffix',
            'email',
            'default_billing',
            'default_shipping',
            'dob',
            'taxvat',
            'id',
            'is_subscribed',
            'mc_newsletter_subscription',
            this._getAddressesField(),
            this._getGtmCustomerField(),
        ];
    }

    _getGtmCustomerField() {
        return new Field('gtm')
            .addFieldList(this._getGtmCustomerFields());
    }

    _getGtmCustomerFields() {
        return [
            'id',
            'level',
        ];
    }

    getCustomerDataWithNewsletterSubscriptionStatus() {
        return this._getCustomerDataWithNLSubscriptionField();
    }
}

export default new MyAccountNewsletterSubscriptionQuery();
